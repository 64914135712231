import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"
// import MenuList from "../components/MenuList"

const IndexPage = () => (
  <Layout title="COVID19 Information">
    <SEO title="COVID19 Information"/>
    <section>
    <div className="container">
        <h2 className="title">From the the co-workers:</h2>
         <p>
         <p>On March 31 <a href="https://livingtohim.com/2020/03/21days-global-prayer/" target="_blank" rel="noopener noreferrer"> the co-workers issued a call</a> to the saints in the Lord’s recovery throughout the globe to pray round-the-clock for twenty-one days starting on April 1. On April 16, <a href="https://unceasinglypray.org/letter/" target="_blank" rel="noopener noreferrer"> a letter from the co-workers extended this season of prayer</a> through the end of April. The Unceasingly Pray website includes <a href="https://unceasinglypray.org/updates/" target="_blank" rel="noopener noreferrer"> words of encouragement from Minoru Chen, Ron Kangas, Andrew Yu, and James Lee</a>, as well as a list of <a href="https://unceasinglypray.org/prayer-burdens/" target="_blank" rel="noopener noreferrer"> prayer burdens</a>, <a href="https://unceasinglypray.org/ministry/" target="_blank" rel="noopener noreferrer"> ministry excerpts</a> on the importance of prayer, and a <a href="https://unceasinglypray.org/sign-up/" target="_blank" rel="noopener noreferrer">signup form</a> to participate.</p>
        </p>
      <br></br>
        <h2 className="title">Valuable resources:</h2>
          <ul>
            <li><a href="https://www.coronavirus.gov/" target="_blank" rel="noopener noreferrer">Federal government coronavirus hub</a></li>
            <li><a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html" target="_blank" rel="noopener noreferrer">Center for Disease Control coronavirus hub</a></li>
            <li><a href="https://covid19.ca.gov/" target="_blank" rel="noopener noreferrer">California Coronavirus (COVID-19) Response</a> hub (translations available)</li>
            <li><a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/nCoV2019.aspx" target="_blank" rel="noopener noreferrer">California Department of Health COVID-19 Updates</a>, including <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/CDPH%20Document%20Library/CDPH%20Guidance%20for%20Mass%20Gatherings%20and%20Large%20Community%20Events_ADA%20Compliant.pdf" target="_blank" rel="noopener noreferrer">guidance for mass gatherings</a> (translations available)</li>
            <li><a href="https://www.ochealthinfo.com/civicax/filebank/blobdload.aspx?BlobID=114422" target="_blank" rel="noopener noreferrer">Amended Order and Guidance of the Orange County Health Officer</a>&#8221; and a useful infographic about the coronavirus in <a href="http://www.ochealthinfo.com/civicax/filebank/blobdload.aspx?BlobID=110477">English</a>, <a href="http://www.ochealthinfo.com/civicax/filebank/blobdload.aspx?BlobID=111616" target="_blank">Traditional Chinese</a>, <a href="http://www.ochealthinfo.com/civicax/filebank/blobdload.aspx?BlobID=111615" target="_blank">Simplified Chinese</a>, <a href="http://www.ochealthinfo.com/civicax/filebank/blobdload.aspx?BlobID=113292" target="_blank" rel="noopener noreferrer">Korean</a>, <a href="http://www.ochealthinfo.com/civicax/filebank/blobdload.aspx?BlobID=111617" target="_blank" rel="noopener noreferrer">Spanish</a>, and <a href="http://www.ochealthinfo.com/civicax/filebank/blobdload.aspx?BlobID=111618" target="_blank" rel="noopener noreferrer">Vietnamese</a></li>
            <li><a href="https://www.cypressca.org/live/virtual-city-hall" target="_blank" rel="noopener noreferrer">City of Cypress COVID19 information</a> resources</li>
            <li>The Environmental Protection Agency has published a <a href="https://www.epa.gov/pesticide-registration/list-n-disinfectants-use-against-sars-cov-2" target="_blank" rel="noopener noreferrer">list of disinfectants for use against coronavirus</a></li>
            <li><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019"target="_blank" rel="noopener noreferrer">World Health Organization Coronavirus</a> disease (COVID-19) Pandemic page</li>
            <li><a href="https://www.consumerreports.org/coronavirus/coronavirus-covid-19-updates/?EXTKEY=EH03COVID2&amp;utm_source=acxiom&amp;utm_medium=email&amp;utm_campaign=20200313_cromc_covid19_2" target="_blank" rel="noopener noreferrer">Consumer Reports coronavirus hub</a>, including a <a href="https://www.consumerreports.org/cleaning/common-household-products-that-can-destroy-novel-coronavirus/" target="_blank" rel="noopener noreferrer">list of common household products that can destroy coronavirus</a></li>
          </ul>
    </div>
    </section>
  </Layout>
)

export default IndexPage
